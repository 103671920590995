
  import { Component, Vue } from "vue-property-decorator";
  import { dispatchGetAnswersReportMonthly } from "@/store/admin/actions";

  @Component
  export default class AdminReports extends Vue {
    picker: string = new Date().toISOString().substr(0, 7);
    downloading = false;
    snackbar = false;

    get month() {
      return this.picker.split("-")[1];
    }

    get year() {
      return this.picker.split("-")[0];
    }

    async onClickDownload() {
      this.downloading = true;
      const resp = await dispatchGetAnswersReportMonthly(this.$store, {
        month: +this.month,
        year: +this.year,
      });

      if (resp) {
        var fileName = "Answer_Report";
        if (resp.headers["content-disposition"]?.includes("filename=")) {
          fileName = resp.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "");
        }
        this.downloadFile(resp.data, fileName);
      } else {
        this.snackbar = true;
      }
      this.downloading = false;
    }

    downloadFile(file: Blob, name: string) {
      var fileURL = window.URL.createObjectURL(file);
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    }
  }
